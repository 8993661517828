<template id="badge-view">
  <form
    ref="form"
    @submit.prevent="handleOk"
  >
    <b-row>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-form-group
          :label="$t('Subgroup') + ' *'"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :placeholder="$t('Subgroup')"
            :state="nameState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
      <b-col
        style="align-self: center;"
        md="3"
      >
        <b-form-checkbox
          v-model="isActive"
          switch
        >
          {{ $t('dataGeneric.activeQ') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          v-for="(field, index) in extraData"
          :key="'card_' + index"
          no-body
          class="field-box"
        >
          <b-row
            class="align-items-center"
            no-gutters
          >
            <b-col md="3">
              <b-form-group
                :label="$t('campo')"
                label-for="field"
              >
                <b-form-input
                  :id="'input_' + index"
                  v-model="field.key"
                  maxlength="150"
                  :placeholder="$t('field')"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="ml-1"
            >
              <b-form-group
                :label="$t('value')"
                label-for="field2"
              >
                <b-form-input
                  :id="'input_' + index"
                  v-model="field.value"
                  maxlength="150"
                  :placeholder="$t('fieldVal')"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="(index !== 0 || (field.key && field.value)) || extraData.length > 1"

              md="1"
              class="d-flex justify-content-center"
            >
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="dropField(index)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="20"
                />
              </b-button>
            </b-col>
            <b-col
              v-if="index === extraData.length - 1"
              md="1"
              class="d-flex justify-content-center"
            >
              <b-button
                class="btn-icon rounded-circle"
                variant="success"
                @click="addNewfield"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="20"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BButton,
  BCard,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { isEmpty, messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    id: { default: null },
  },
  data() {
    return {
      show: false,
      name: '',
      extraData: [],
      userData: getUserData(),
      nameState: null,
      isActive: true,
    }
  },

  mounted() {
    if (this.id) this.getSubgroup()
    else this.fillExtraData([])
  },
  methods: {
    dropField(index) {
      this.extraData.splice(index, 1)
      if (isEmpty(this.extraData)) {
        this.addNewfield()
      }
    },
    fillExtraData(extraData) {
      if (isEmpty(extraData) || extraData === '{}') {
        this.addNewfield()
      } else {
        const data = JSON.parse(extraData)
        Object.keys(data).forEach(key => {
          this.extraData.push({ key, value: data[key] })
        })
      }
    },
    addNewfield() {
      this.extraData.push({ key: '', value: '' })
    },
    getSubgroup() {
      const { headers } = this
      axios
        .post('', {
          query: `
        {
            allSubgroups(id:"${this.id}")
            {
            edges
            {
                node
                {
                        id
                        name
                        extraData
                        isActive
                    }
                }
            }
        }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const { edges } = res.data.data.allSubgroups
          this.name = edges[0].node.name
          this.isActive = edges[0].node.isActive
          this.fillExtraData(edges[0].node.extraData)
          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },
    handleOk() {
      if (this.id !== null) { this.editSubgroup() } else this.createSubgroup()
    },
    createSubgroup() {
      if (!this.checkFormValidity()) {
        return
      }
      const { headers } = this
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let extraData = this.extraData.reduce((acc, item) => {
        if (item.key) {
          acc[item.key] = item.value
        }
        return acc
      }, {})
      extraData = JSON.stringify(extraData)
      const query = `
            mutation($name:String!,$isActive:Boolean,$client:ID!,$extraData:JSONString){
              createSubgroup(input:{name:$name,client:$client,isActive:$isActive,
                  extraData:$extraData
              }){
                subGroup {
                  id
                }
              }
            }
          `
      const variables = {
        name: this.name, extraData, isActive: this.isActive, client: userData.profile.client.id,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$nextTick(() => {
            this.$bvModal.hide('subgroup-view')
          })
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },

    editSubgroup() {
      const { headers } = this

      if (!this.checkFormValidity()) {
        return
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let extraData = this.extraData.reduce((acc, item) => {
        if (item.key) {
          acc[item.key] = item.value
        }
        return acc
      }, {})
      extraData = JSON.stringify(extraData)
      const query = `mutation($name:String,$isActive:Boolean,$extraData:JSONString){
            updateSubgroup(id:"${this.id}",input:{name:$name,isActive:$isActive,extraData:$extraData}){
              subGroup{
                id
              }
            }
          }`
      const variables = { name: this.name, isActive: this.isActive, extraData }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$nextTick(() => {
            this.$bvModal.hide('subgroup-view')
          })
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },

  },
}
</script>
